<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import Home from '@/components/home.vue'

export default {
  name: 'App',
  components: {
    Home,
  }
}
</script>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?8037a5eea76f3b30a19a602140903f72";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

/* ::-webkit-scrollbar {
  width: 10px;
  background-color: #f9f9f9;
} */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 计算1920时字体大小为33px,则100vw下为1.71875vw */

html {
  font-size: 1.72vw;
}

@media (max-width: 1000px) {
  html {
    font-size: 1.82vw;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 1.92vw;
  }
}

/* element 弹窗修改 */
.elPop {
  min-width: auto !important;
  padding: 0.36rem !important;
  border: 0.03rem solid #EBEEF5 !important;
  border-radius: 0.2rem !important;
  margin-top: 0.5rem !important;
}

/*
@media (min-width: 376px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 415px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 640px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 720px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 750px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 800px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 18px;
    font-family: arial;
  }
}

@media (min-width: 1100px) {
  html {
    font-size: 20px;
    font-family: arial;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 22px;
    font-family: arial;
  }
}

@media (min-width: 1366px) {
  html {
    font-size: 24px;
    font-family: arial;
  }
}

@media (min-width: 1440px) {
  html {
    font-size: 25px;
    font-family: arial;
  }
}

@media (min-width: 1680px) {

  html {
    font-size: 28px;
    font-family: arial;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 33px;
    font-family: arial;
  }
}

@media (min-width: 2100px) {
  html {
    font-size: 36px;
    font-family: arial;
  }
}

@media (min-width: 2300px) {
  html {
    font-size: 39px;
    font-family: arial;
  }
}

@media (min-width: 2500px) {
  html {
    font-size: 42px;
    font-family: arial;
  }
}

@media (min-width: 2700px) {
  html {
    font-size: 45px;
    font-family: arial;
  }
}

@media (min-width: 2900px) {
  html {
    font-size: 48px;
    font-family: arial;
  }
}

@media (min-width: 3100px) {
  html {
    font-size: 52px;
    font-family: arial;
  }
}

@media (min-width: 3300px) {
  html {
    font-size: 55px;
    font-family: arial;
  }
}

@media (min-width: 3500px) {
  html {
    font-size: 58px;
    font-family: arial;
  }
}

@media (min-width: 3700px) {
  html {
    font-size: 62px;
    font-family: arial;
  }
}

@media (min-width: 3840px) {
  html {
    font-size: 66px;
    font-family: arial;
  }
} */
</style>