import VueRouter  from "vue-router";
import Vue  from "vue";
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../components/home.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/video",
        name: "video",
        component: () => import("../components/video.vue"),
        meta: {
            title: '短剧开发'
        }
    },
    {
        path: "/car",
        name: "car",
        component: () => import("../components/car.vue"),
        meta: {
            title: '代驾打车'
        }
    },
		{
		    path: "/case",
		    name: "case",
		    component: () => import("../components/case.vue"),
		    meta: {
		        title: '产品案例'
		    }
		},
		{
		    path: "/about",
		    name: "about",
		    component: () => import("../components/about.vue"),
		    meta: {
		        title: '关于我们'
		    }
		},
		{
		    path: "/caseDetail",
		    name: "caseDetail",
		    component: () => import("../components/caseDetail.vue"),
		    meta: {
		        title: '案例详情'
		    }
		},
		{
		    path: "/problemDetail",
		    name: "problemDetail",
		    component: () => import("../components/problemDetail.vue"),
		    meta: {
		        title: '咨询详情'
		    }
		},
];

const router = new VueRouter({
	mode:'history',
    routes
});

export default router;