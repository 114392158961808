import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import ElementUI from 'element-ui';
import request from "./utils/request";
import VueRouter from 'vue-router'
import router from './router/router'
import 'element-ui/lib/theme-chalk/index.css';
import Titles from './components/title.vue';
import Bottom from './components/bottom.vue';
import aboutUs from './components/about_us.vue';
import functionBox from './components/function_box.vue';
import caseList from './components/case_list.vue';
import caseTop from './components/case_top.vue';

Vue.prototype.request = request
Vue.prototype.$baseUrl = 'https://admin.hzhsyh.com'
// 注册组件
Vue.component('titles', Titles);
Vue.component('Bottom', Bottom);
Vue.component('aboutUs', aboutUs);
Vue.component('functionBox', functionBox);
Vue.component('caseList', caseList);
Vue.component('caseTop', caseTop);

Vue.prototype.request = request
Vue.use(VueRouter);
Vue.use(ElementUI);
new Vue({
	render: h => h(App),
	router: router
}).$mount('#app')